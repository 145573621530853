.sidebar {
    position: fixed;
    top: calc(50% - 41.5px);
    padding: 12px 8px 12px 12px;
    right: 0;
    left: auto;
    background: $white;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #D9D9D9;
    border-radius: 20px 0px 0px 20px;
    z-index: 4;
    .social {
        display: flex;
        align-items: center;
        flex-direction: column;
        &__link {
            width: 22px;
            height: 22px;
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &:not(:first-of-type) {
                margin-top: 15px;
            }
            &--vk {
                background-image: url(../img/svg/vk.svg);
                &:hover {
                    background-image: url(../img/svg/vk-g.svg);
                }
            }
            &--inst {
                background-image: url(../img/svg/inst.svg);
                &:hover {
                    background-image: url(../img/svg/inst-g.svg);
                }
            }
        }
    }
}