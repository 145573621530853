.article {
    &-header {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 160px 0 112px;
        position: relative;
        min-height: 478px;
        @media #{$md} {
            min-height: 405px;
            background-color: rgba(217, 217, 217, 0.3);
            padding: 437px 0 24px;
        }
        @media #{$sm} {
            min-height: 294px;
            padding: 320px 0 34px;
        }
        &__img {
            background-image: url(../img/article.jpg);
            position: absolute;
            content: "";
            top: 0;
            left: 0%;
            width: 100%;
            height: 100%;
            margin-left: 0;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @media #{$md} {
                width: 100%;
                left: 0;
                max-height: 405px;
                background-position: center;
                margin-left: 0;
                background-size: cover;
            }
            @media #{$sm} {
                max-height: 294px;
                background-size: cover;
            }
        }
        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            top: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(270deg, rgba(74, 74, 74, 0.18) 0%, #4A4A4A 100%);
            @media #{$md} {
                display: none;
            }
        }
        &__title {
            position: relative;
            z-index: 2;
            font-family: $alethia;
            font-size: 4.6rem;
            line-height: 1.3;
            color: $white;
            max-width: 700px;
            @media #{$md} {
                font-size: 4rem;
                color: $d-black;
            }
            @media #{$sm} {
                font-size: 3rem;
            }
        }
        &__description {
            position: relative;
            z-index: 2;
            margin-top: 16px;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.6;
            color: $white;
            max-width: 743px;
            @media #{$md} {
                font-size: 1.8rem;
                line-height: 1.8;
                font-weight: 500;
                color: $d-black;
            }
        }
    }
}