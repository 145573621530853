.reviews {
    padding: 60px 0 80px;
    &__item {
        &:not(:first-child) {
            margin-top: 40px;
        }
    }
    &__title {
        font-family: $alethia;
        font-size: 2.4rem;
        line-height: 1.5;
        color: $d-black;
        margin-bottom: 12px;
    }
    &__text {
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.7;
        color: $d-black;
        margin-top: 12px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    &__link {
        cursor: pointer;
        position: relative;
        margin-top: 12px;
        padding-left: 32px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.8;
        color: #949c8d;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            height: 100%;
            width: 24px;
            background: url(../img/svg/eye.svg) top no-repeat;
        }
    }
    &__popup {
        display: flex;
        z-index: 500;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: #1b1a1a99;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        &.js-hide {
            display: none;
        }
        &.js-show {
            display: flex;
        }
        &-wrapper {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            text-align: center;
        }
        &-img {
            position: relative;
            max-width: 488px;
            & img {
                max-height: 80vh;
            }
        }
        &-close {
            position: absolute;
            content: "";
            top: 0px;
            right: -72px;
            width: 22.5px;
            height: 22.5px;
            background: url(../img/svg/close.svg) center no-repeat;
            background-size: contain;
            cursor: pointer;
            @media #{$sm} {
                top: -35px;
                right: 0;
            }
        }
    }
}