.callback {
    padding: 40px 0 50px;
    &--bg {
        background-color: #F5F8F4;
    }
    &__title {
        font-family: $alethia;
        font-size: 2.4rem;
        line-height: 1.5;
        color: $d-black;
        @media #{$lg} {
          width: 100%;
        }
        @media #{$sm} {
          margin-bottom: 6px;
        }
    }
    &__subtitle {
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.8;
        color: $d-black;
        @media #{$md} {
          margin-top: 13px;
          width: 100%;
        }
    }
}
.onerow-form {
    display: flex;
    margin-top: 27px;
    @media #{$md} {
      display: block;
      width: 100%;
    }
    @media #{$sm} {
      margin-top: 37px;
    }
    .form_block {
        display: flex;
        @media #{$sm} {
          flex-wrap: wrap;
        }
    }
    & .fw-placeholder {
        width: 300px;
        &:not(:first-of-type) {
            margin-left: 32px;
            @media #{$md} {
              margin-left: 26px;
            }
            @media #{$esm} {
              margin-top: 30px;
              margin-left: 0;
            }
        }
        @media #{$lg} {
          width: 216px;
        }
        @media #{$md} {
          width: calc(50% - 13px);
        }
        @media #{$esm} {
          width: 100%;
        }
    }
    & .form_block--checkbox {
        margin-left: 35px;
        @media #{$md} {
          margin-top: 30px;
          margin-left: 0;
        }
        @media #{$esm} {
          display: block;
          text-align: center;
        }
        & .btn {
            margin-left: 38px;
            align-self: flex-start;
            @media #{$md} {
              margin-left: 0;
            }
            @media #{$sm} {
              margin-top: 0;
            }
            @media #{$esm} {
              margin: 30px auto 0;
            }
        }
    }
}
.tworow-form {
    display: flex;
    margin-top: 27px;
    @media #{$md} {
      flex-wrap: wrap;
    }
    @media #{$esm} {
      display: block;
    }
    & .form_block--inputs {
      flex: 0 0 31%;
      @media #{$md} {
        flex: 0 0 48%;
      }
    }
    & .fw-placeholder {
        &:not(:first-of-type) {
            margin-top: 30px;
        }
    }
    & .form_block--textarea {
      margin-left: 25px;
      flex: 0 0 40%;
      align-self: stretch;
      @media #{$md} {
        margin-left: auto;
        flex: 0 0 48%;
      }
      @media #{$esm} {
        margin-top: 30px;
        height: 192px;
      }
      & .fw-placeholder.textarea {
        height: 100%;
        width: 100%;
      }
      & textarea {
        height: 100%;
        width: 100%;
      }
    }
    & .form_block--checkbox {
      margin-left: auto;
      max-width: 233px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media #{$md} {
        flex-direction: row;
        margin-top: 15px;
        width: 100%;
        max-width: initial;
      }
      @media #{$sm} {
        & .btn {
          margin-top: 0;
        }
      }
      @media #{$esm} {
        display: block;
        text-align: center;
        & .btn {
          margin-top: 35px;
        }
      }

    }
}
.callback__form {
  & .contacts__title {
      font-size: 3rem;
  }
  @media #{$md} {
      display: flex;
      flex-wrap: wrap;
  }
  @media #{$sm} {
      display: block;
  }
  & .btn {
      @media #{$lg} {
          font-size: 1.4rem;
          font-weight: 600;
          white-space: nowrap;
      }
      @media #{$md} {
          margin-left: auto;
      }
      @media #{$sm} {
          margin-top: 35px;
      }
  }
  & .callback-form {
    &.form_block--inputs {
      margin-top: 32px;
        @media #{$md} {
            width: 39%;
            margin-right: 24px;
        }
        @media #{$sm} {
            width: 100%;
      }
      & .fw-placeholder {
        &:not(:first-child) {
          margin-top: 30px;
        }
      }
      & .fw-input {
        border-radius: 6px;
      }
      & .error-placeholder {
        top: calc(100% - 10px);
      }
    }
    &.form_block--textarea {
      margin-top: 25px;
        @media #{$md} {
            margin-top: 32px;
            width: calc(61% - 24px)
        }
        @media #{$sm} {
            width: 100%;
        }
        & .fw-placeholder textarea{
          border-radius: 6px;
            @media #{$md} {
                height: 134px;
            }
            @media #{$sm} {
                height: 192px;
            }
        }
    }
    &.form_block--checkbox {
      margin-top: 30px;
        @media #{$md} {
            width: 100%;
        }
        & .fw-placeholder {
            @media #{$sm} {
                display: block;
            }
        }
        & .checkbox:not(checked)+label {
          font-size: 1.2rem;
          @media #{$sm} {
            font-size: 1.4rem;
          }
        }
    }
  }
}

form .fw-placeholder-select,
form .fw-placeholder {
   position: relative;
   display: flex;
   align-items: center;
   font-size: 0;
 
   .fw-input {
        height: 52px;
        font-size: 14px;
        font-weight: 500;
        @media #{$sm} {
          font-size: 18px;
        }
        + .placeholder {
            font-size: 14px;
            font-weight: 600;
            top: -7px;
            background: #F5F8F4;
            color: $d-black;
            @media #{$sm} {
              font-size: 16px;
          }
     }
   }
 
 
   .fw-input, textarea {
     font-family: $montserrat;
     width: 100%;
     //height: 100%;
     //height: 57px;
     background: transparent;
     border: 1px #9e9f9d solid;
     -webkit-transition: 0.4s;
     -moz-transition: 0.4s;
     -ms-transition: 0.4s;
     -o-transition: 0.4s;
     transition: 0.4s;
     font-weight: 400;
 
     &:focus {
       + .placeholder {
         top: -7px;
         font-size: 14px;
         background: #F5F8F4;
         cursor: default;
         color: #838383;
         @media #{$sm} {
           font-size: 16px;
         }
       }
     }
 
     &.wrong {
       background-color: #ffe6e6;
       
       + .placeholder {
        border-radius: 10px;
         color: #e90000;
         background-color: #ffe6e6;
 
         + .error-placeholder {
           opacity: 1;
           top: 100%;
         }
       }
     }
   }
 
   textarea {
    padding: 17px 18px;
    height: 121px;
    font-family: $montserrat;
    font-size: 1.4rem;
    font-weight: 400;
    @media #{$sm} {
      font-size: 18px;
      padding: 17px 16px;
    }
    &::placeholder {
      font-family: $montserrat;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      color: #949494;
      @media #{$sm} {
        font-size: 18px;
      }
    }
    + .placeholder {
      font-size: 14px;
      font-weight: 600;
      top: -7px;
      background: #F5F8F4;
      color: $d-black;
      @media #{$sm} {
        font-size: 16px;
      }
    }
     &:focus {
       + .placeholder {
         //top: -8px;
       }
     }
   }
 
   &.wrong {
 
     &:before {
       display: none;
       content: "!";
      // нужна переменная font: 19px/19px $font_default;
       color: #fff;
       margin-top: -10px;
       position: absolute;
       right: 5px;
       height: 21px;
       width: 21px;
       border-radius: 21px;
       background: #e90000;
       align-items: center;
       justify-content: center;
     }
   }
 
   textarea.fw-input {
     height: 200px;
     @media #{$sm} {
      height: 140px;
     }
     &:focus {
       //+ .placeholder {
       //  top: -2px;
       //  font-size: 14px;
       //  background: #f5f5f5;
       //  cursor: default;
       //  opacity: 1;
       //}
     }
   }
 
   .placeholder {
     position: absolute;
     left: 12px;
     top: 15px;
    // нужна переменная font-family: $font_default;
     font-size: 14px;
     line-height: 1;
     cursor: text;
     padding: 0 5px;
     -webkit-transition: 0.4s;
     -moz-transition: 0.4s;
     -ms-transition: 0.4s;
     -o-transition: 0.4s;
     transition: 0.4s;
     color: #838383;
     user-select: none;
     background: transparent;
     @media #{$sm} {
       font-size: 16px;
     }
   }
 
   &.active {
     .placeholder {
       top: -7px;
       font-size: 14px;
       //background: #fff;
       background: #f5f5f5;
       cursor: default;
       opacity: 1;
       -webkit-user-select: none;
       -moz-user-select: none;
       -ms-user-select: none;
       user-select: none;
       @media #{$sm} {
         font-size: 16px;
       }
     }
 
     textarea + .placeholder {
       //top: -8px;
     }
   }
 
   .error-placeholder {
     display: block;
     position: absolute;
     left: 0;
     top: calc(100% - 5px);
    // нужна переменная font-family: $font_default;
     font-size: 14px;
     opacity: 0;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     color: #e90000;
   }
 }
 
 .float-box {
   form .fw-placeholder {
     .placeholder {
       background: #fff !important;
     }
 
     .fw-input, textarea {
       &:focus {
         //+ .placeholder{
         //  background: #fff !important;
         //  top: -2px !important;
         //}
       }
 
       &.wrong {
         background-color: #ffe6e6;
 
         + .placeholder {
           color: #e90000 !important;
           background-color: #ffe6e6 !important;
         }
       }
     }
 
     .fw-placeholder.active {
       //.placeholder{
       //  background: #fff;
       //  top: -2px !important;;
       //}
    }
    }
}
.checkbox {
    vertical-align: top;
    margin: 0;
    width: 0;
    height: 0;
    opacity: 0;
  
    &:not(checked) {
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-filter: alpha(opacity=0);
      -moz-filter: alpha(opacity=0);
      -o-filter: alpha(opacity=0);
      filter: alpha(opacity=0);
  
      & + label {
        position: relative;
        padding: 0 0 0 36px;
        margin-right: auto;
        color: $d-black;
        font-weight: 500;
        font-size: 1.4rem;
        // нужна переменная font-family: $font_default;
        cursor: pointer;
        line-height: 1.8;
        display: flex;
        align-items: flex-start;
        @media #{$md} {
          font-size: 1.4rem;
          padding: 0 0 0 36px;
        }     
        p, span {
          color: #000;
         // нужна переменная font-family: $font_default;
        }
  
        span {
          cursor: pointer;
          text-decoration: none;
          color: #949C8D;
         // нужна переменная  border-bottom: 1px dashed $blue;
         // нужна переменная color: $blue;
        }
  
        &:after {
          content: '';
          position: absolute;
          left: 0;
          width: 20px;
          height: 20px;
          background: transparent;
          -webkit-transition: none;
          -moz-transition: none;
          -o-transition: none;
          transition: none;
          border: 1px solid #949494 ;
          @media #{$md} {
            width: 20px;
            height: 20px;
          }
          @media #{$sm} {
            width: 26px;
            height: 26px;
          }
          @media #{$esm} {
            top: 2px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  
    &:checked + label:after {
      //content: '';
      background-image: url("../img/svg/check.svg");
      background-position: center right;
      background-repeat: no-repeat;
      background-size: 90% 90%;
      //border: 0;
      //cursor: pointer;
      //color: white;
     // нужна переменная font: 21px $font_awesome;
      font-weight: 100;
      cursor: pointer;
      color: #214895;
    }
  }
 
 form .wrong,
 .checkbox:not(checked).wrong + label:after {
   border-color: #e90000 !important;
 }
 
 .checkbox:not(checked).wrong + label:after {
   background-color: #ffe6e6 !important;
 }
 