.services {
    padding: 50px 0;
    @media #{$md} {
        padding-bottom: 0;
    }
    &__list {
        margin-top: 60px;
        flex-wrap: wrap;
        display: flex;
        @media #{$sm} {
            margin-top: 35px;
            display: block;
        }
    }
    &__item {
        position: relative;
        width: calc(50% - 12px);
        background-color: #ebebeb;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center right;
        cursor: pointer;
        @media #{$sm} {
            width: 100%;
            margin-right: 0;
            margin-bottom: 32px;
        }
        &:hover {
            & .services__item-more {
                z-index: 1;
                opacity: 0.5;
                left: 0;
            }
        }
        &:nth-child(odd) {
            margin-right: 24px;
        }
        &:nth-child(n + 3) {
            margin-top: 24px;
        }
        &:nth-child(1) {
            & .services__item-img {
                top: -50px;
                @media #{$sm} {
                    top: -25px;
                }
            }
        }
        &:nth-child(2) {
            background-color: #dae2d7;
            overflow: hidden;
            & .services__item-img {
                max-width: 65%;
                top: 12px;
                @media #{$lg} {
                    max-width: 288px;
                }
                @media #{$md} {
                    max-width: 273px;
                    right: -20px;
                }
            }
        }
        &:nth-child(3) {
            background-color: #dae2d7;
            & .services__item-img {
                top: auto;
                max-width: 55%;
                bottom: -50px;
                right: -10px;
                @media #{$lg} {
                    max-width: 240px;
                }
                @media #{$md} {
                    max-width: 200px;
                    bottom: 0;
                }
            }
        }
        &:nth-child(4) {
            overflow: hidden;
            & .services__item-img {
                top: 2px;
                max-width: 50%;
                @media #{$lg} {
                    max-width: 200px;
                }
            }
        }
        &-inner {
            display: block;
            padding: 120px 47px;
            @media #{$lg} {
                padding: 87px 47px;
            }
            @media #{$md} {
                padding: 82px 35px;
            }
            @media #{$sm} {
                padding: 87px 12px 67px;
            }
        }
        &-title {
            max-width: 80%;
            position: relative;
            z-index: 2;
            font-family: $alethia;
            font-size: 4rem;
            line-height: 1;
            color: $black;
            &--short {
                max-width: 201px;
            }
            & span {
                position: absolute;
                top: -17px;
                left: 3px;
                display: block;
                font-family: $montserrat;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.15;
                @media #{$sm} {
                    top: -20px;
                }
            }
        }
        &-img {
            position: absolute;
            max-width: 55%;
            top: 0;
            right: 0;
            z-index: 1;
            @media #{$lg} {
                max-width: 248px;
            }
            @media #{$md} {
                max-width: 233px;
            }
        }
        &-more {
            position: relative;
            z-index: -1;
            opacity: 0;
            left: -200px;
            bottom: -48px;
            letter-spacing: 0.17em;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.15;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            @media #{$sm} {
                bottom: -33px;
                font-size: 1.2rem;
                left: 0;
                opacity: 0.5;
                z-index: 1;
            }
        }
    }
}
.about {
    padding: 100px 0 60px;
    background: linear-gradient(0, #ecefea 0%, rgba(216, 222, 212, 0) 100%);
    @media #{$lg} {
        padding: 60px 0;
    }
    &__inner {
        display: flex;
        position: relative;
        width: calc(100% + 120px);
        left: -120px;
        @media #{$xl} {
            width: 100%;
            left: 0;
        }
        @media #{$lg} {
            flex-direction: column;
            align-items: center;
        }
    }
    &__img {
        width: calc(45% + 120px);
        @media #{$xl} {
            width: 50%;
            padding-right: 20px;
        }
        @media #{$lg} {
            width: 100%;
            max-width: 443px;
            padding-right: 0;
            margin-bottom: 30px;
        }
        @media #{$sm} {
            width: 100%;
            max-width: 332px;
            padding-right: 0;
            margin-bottom: 30px;
        }
    }
    &__description  {
        margin-top: 10px;
        width: calc(55% - 120px);
        @media #{$xl} {
            width: 50%;
            padding-left: 20px;
        }
        @media #{$lg} {
            width: 100%;
            padding-left: 0;
        }
    }
    &__title  {
        font-family: $alethia;
        font-size: 3rem;
        line-height: 1.4;
        @media #{$lg} {
            text-align: center;
        }
        @media #{$sm} {
            font-size: 2.4rem;
        }
        &:not(:first-of-type) {
            margin-top: 12px;
        }
    }
    &__text {
        margin-top: 48px;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.5;
        @media #{$sm} {
            margin-top: 40px;
            font-size: 2.2rem;
        }
    }
    &__list {
        margin-top: 18px;
        @media #{$md} {
            max-width: 98%;
        }
    }
    &__list-item {
        font-family: $montserrat;
        padding-left: 20px;
        position: relative;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.55;
        @media #{$md} {
            font-size: 1.8rem;
        }
        &::before {
            position: absolute;
            left: 0;
            top: 7px;
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #b6c3ae;
        }
        &:not(:first-of-type) {
            margin-top: 11px;
        }
    }
    &__more {
        display: inline-block;
        margin-top: 50px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.15;
        color: #777977;
        letter-spacing: 0.17em;
        position: relative;
        transition: all .3s ease-in-out;
        &:hover {
            color: $black;
            &::after {
                background: url(../img/svg/arrow-link-b.svg) center no-repeat;
            }
        }
        &::after {
            position: absolute;
            right: -17px;
            content: "";
            width: 5px;
            height: 100%;
            background: url(../img/svg/arrow-link.svg) center no-repeat;
        }
    }
}
.advantages {
    padding: 60px 0 80px;
    & + .container.page {
        @media #{$sm} {
            padding-top: 40px;
        }
    }
    &--bg {
        background: url(../img/about/advantages.jpg) center no-repeat;
        background-size: cover;
        color: $white;
        & .section-title {
            color: $white;
        }
        & .advantages__text {
            opacity: 1;
        }
    }
    &__list {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: calc(33.33% - 16px);
        margin-right: 24px;
        &:nth-child(3n) {
            margin-right: 0;
        }
        &:nth-child(n + 4) {
            margin-top: 35px;
        }
        @media #{$lg} {
            width: calc(50% - 13.5px);
            margin-right: 27px;
            &:nth-child(3n) {
                margin-right: 27px ;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
            &:nth-child(n + 3) {
                margin-top: 35px;
            }
        } 
        @media #{$sm} {
            width: 100%;
            margin-top: 0;
            &:not(:first-child) {
                margin-top: 40px;
            }
            &:nth-child(3n) {
                margin-right: 0 ;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }
    &__title {
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.3;
        margin-bottom: 11px;
    }
    &__text {
        font-size: 1.4rem;
        line-height: 1.5;
        opacity: 0.7;
    }
}
.partners {
    & .slider-arrows {
        @media #{$md} {
            display: none;
        }
    }
    background-color: #f5f5f5;
    position: relative;
    padding: 75px 0 210px;
    @media #{$md} {
        padding: 40px 0;
    }
    &__text {
        max-width: 419px;
        display: inline-block;
        margin-top: 18px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.5;
        color: #212121;
        margin-bottom: 70px;
        @media #{$lg} {
            margin-bottom: 40px;
        }
        @media #{$md} {
            max-width: 75%;
            margin-top: 12px;
            font-size: 1.8rem;
        }
        @media #{$sm} {
            max-width: 100%;
        }
    }
    .swiper-container {
        position: absolute;
        top: 20%;
        right: 0px;
        width: 57%;
        @media #{$lg} {
            top: 16%;
            width: calc(100% - 480px);
        }
        @media #{$md} {
            top: 0;
            left: 0;
            width: 100%;
            padding-left: 24px;
            position: relative;
        }
        @media #{$sm} {
            padding-left: 15px;
        }
    }
    .swiper-buttons {
        display: flex;
        margin-top: 15px;
    }
    &__item {
        width: 214px;
        height: auto;
        text-align: center;
        background: $white;
        padding: 10px 10px 25px;
    }
    &__img {
        height: 150px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    &__name {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.3;
    }
    &__description {
        margin-top: 5px;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.3;
    }
    &__link {
        display: block;
        margin-top: 15px;
        text-decoration: underline;
        font-size: 1.4rem;
        line-height: 1.2;
        color: #92A387;
    }
}
.articles {
    padding: 60px 0;
    overflow: hidden;
    @media #{$md} {
        padding: 40px 0;
    }
    & .slider-arrows {
        @media #{$md} {
            display: none;
        }
    }
    &__slider {
        margin-top: 40px;
        height: 355px;
        overflow: visible;
        position: relative; 
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 200%;
            left: -100%;
            top: -10%;
            background-color: $white;
            z-index: 5;
        }
        @media #{$lg} {
            height: 305px;
        }
        @media #{$md} {
            height: auto;
        }
    }
    &__item {
        width: 42%;
        overflow: hidden;
        @media #{$lg} {
            width: 45%;
        } 
        @media #{$md} {
            width: 50.75%;
        }
        @media #{$sm} {
            width: 100%;
        }
    }
    &__block {
        display: flex;
        max-height: 372px;
        flex-direction: column;
        position: relative;
        &:hover, &:active {
            & .articles__text {
                max-height: 300px;
            }
            & .articles__details {
                max-height: 300px;
            }
        }
    }
    &__img {
        margin-bottom: 65px;
        height: 290px;
        @media #{$lg} {
            height: 238px;
            margin-bottom: 42px;
        } 
        @media #{$md} {
            height: 212px;
            margin-bottom: 0;
        }
        @media #{$md} {
            height: 166px;
        }
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media #{$md} {
                width: 100%;
            }
        }
    }
    &__description {
        position: absolute;
        bottom: 0;
        left: 0;
        background: $white;
        overflow: hidden;
        min-height: 74px;
        @media #{$md} {
            position: relative;
            min-height: initial;
        }
    }
    &__title {
        font-family: $montserrat;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.3;
        color: $black;
        margin-top: 16px;
        color: $d-black;
        @media #{$lg} {
            margin-top: 12px;
        } 
        @media #{$md} {
            margin-top: 8px;
        }
        @media #{$sm} {
            margin-top: 14px;
        }
    }
    &__text {
        display: block;
        max-height: 0;
        overflow: hidden;
        margin-top: 4px;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.5;
        color: $black;
        transition: all .7s ease-in-out;
        @media #{$md} {
            max-height: initial;
            font-size: 0;
        }
    }
    &__details {
        max-height: 0;
        display: block;
        overflow: hidden;
        margin-top: 18px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.15;
        letter-spacing: 0.17em;
        opacity: 0.5;
        color: $black;
        transition: all 0.7s ease-in-out;
        @media #{$md} {
            display: none;
        }
    }
}
