.header-half {
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 72px 0 126px;
    min-height: 447px;
    flex-direction: column;
    background-color: $white;
    justify-content: center;
    &__img {
        position: absolute;
        content: "";
        top: 0;
        left: 50%;
        width: calc(50% + 35px);
        height: 100%;
        margin-left: -35px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media #{$md} {
            width: 100%;
            left: 0;
            max-height: 405px;
            background-position: center;
            margin-left: 0;
            background-image: url(../img/news/header.jpg);
            background-size: cover;
        }
        @media #{$sm} {
            max-height: 294px;
            background-image: url(../img/news/header.jpg);
            background-size: cover;
        }
    }
    @media #{$lg} {
        min-height: 411px;
    }
    @media #{$md} {
        display: block;
        padding-top: 437px;
        padding-bottom: 32px;
    }
    @media #{$sm} {
        display: block;
        padding-top: 320px;
        padding-bottom: 26px;
    }
    &__text {
        max-width: 45%;
        @media #{$md} {
            max-width: 100%;
        }
    }
    &__title {
        font-family: $alethia;
        font-size: 3rem;
        line-height: 1.3;
    }
    &__description {
        font-family: $montserrat;
        margin-top: 16px;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.8;
        color: #4a4a4a;
        &:not(:first-of-type) {
            margin-top: 8px;
        }
    }
}