.how {
    &-work {
        padding: 60px 0 40px;
        & + .page {
            padding-top: 0;
        }
    }
    &__work-table {
        margin-top: 40px;
    }
    &__work-row {
        display: grid;
        grid-template-columns: 87px 1fr 45%;
        padding: 36px 30px 26px 0;
        &:not(:first-child) {
            border-top: 1px solid #d1d1d1;
        }
        @media #{$md} {
            grid-template-columns: 63px 1fr;
            grid-template-rows: auto auto;
        }
        @media #{$sm} {
            border-top: 0;
            grid-template-columns: 1fr;
            padding: 0;
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }
    }
    &__work-number {
        font-family: $alethia;
        margin-top: 0;
        font-size: 3.2rem;
        line-height: 1.5;
        color: #949C8D;
        text-shadow: 0 0 2px #FDC826;
        margin-right: 24px;
        @media #{$md} {
            grid-row: 1/3;
        }
        @media #{$sm} {
            font-size: 2.6rem;
            grid-row: 1/2;
        }
    }
    &__work-title {
        max-width: 372px;
        font-family: $alethia;
        padding-right: 10px;
        font-size: 3.6rem;
        line-height: 1.2;
        @media #{$md} {
            font-size: 3rem;
            line-height: 1.2;
            grid-row: 1/2;
            grid-column: 2/3;
            @media #{$sm} {
                margin-top: 6px;
                font-size: 2.4rem;
                grid-row: 2/3;
                grid-column: 1/2;
            }
        }
    }
    &__work-text {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.8;
        @media #{$md} {
            grid-row: 2/3;
            grid-column: 2/3;
        }
        @media #{$sm} {
            margin-top: 12px;
            font-size: 1.8rem;
            grid-row: 3/4;
            grid-column: 1/2;
        }
    }
}