
 .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 0;
  
    .next {
      padding: 0;
  
      a {
        background-color: transparent;
        color: $black;
  
        &::after {
          content: url("/img/svg/arrow-next-b.svg");
          position: absolute;
          top: 11px;
          right: 0;
        }
  
        &:hover {
          background-color: transparent;
          color: $black;
          box-shadow: none;
        }
      }
    }
  
    .prev {
      padding: 0;
  
      a {
        background-color: transparent;
        color: $black;
  
        &::after {
          content: url("/img/svg/arrow-prev-b.svg");
          position: absolute;
          left: 0;
          top: 11px;
        }
  
        &:hover {
          background-color: transparent;
          color: $black;
          box-shadow: none;
        }
      }
    }
  
    li {
      display: inline-block;
      font-family: $montserrat;
      font-weight: 700;
      margin: 0 11px;
      user-select: none;
      font-size: 18px;
      line-height: 1;
      list-style-type: none;
      a, span {
        font-size: 18px;
        line-height: 1;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        padding: 10px 15px;
        border-radius: 6px;
        border: 1px solid transparent;
      }
  
      &.active span {
        font-weight: 700;
        margin: 0 11px;
        width: 46px;
        font-size: 18px;
        line-height: 1;
        background-color: transparent;
        border: 1px solid #949C8D;
        border-radius: 31px;
        &:hover {
            opacity: 1;
        }
      }
  
      a:hover,
      span:hover {
       opacity: 0.5;
      }
  
      &.active {
        position: relative;
  
        span {
          display: block;
          text-align: center;
        }
      }
  
      &.prev a,
      &.next a {
        font-weight: 500;
        position: relative;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 20px;
        &:hover {
         border: 1px solid transparent;
        }
        @media #{$md} {
         display: none;
        }
      }
  
      &.next a {
        padding-left: 0;
        padding-right: 20px;
      }
  
      &.first {
        margin-right: 0;
        white-space: nowrap;
  
        &:after {
          content: "...";
          font-size: 20px;
          margin-left: 5px;
        }
      }
  
      &.last {
        margin-left: 0;
        white-space: nowrap;
  
        &:before {
          content: "...";
          font-size: 20px;
         // нужна переменная font-family: $font_bold;
          margin-right: 5px;
        }
      }
  
    }
  }