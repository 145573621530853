.slinky-menu {
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /*overflow: auto;*/
  overflow: hidden;
}

.slinky-menu>ul {
  left: 0;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.slinky-menu ul, .slinky-menu li {
  list-style: none;
  margin: 0;
}

.slinky-menu ul {
  width: 100%;
}

.slinky-menu > ul{
  /*margin-top: 12px;*/
}

.slinky-menu a ,
.slinky-menu .next,
.back,
.back-icon{
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slinky-menu .next,.slinky-menu .back{
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.slinky-menu a span:not(.back-icon) {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.4;
}

.slinky-menu li ul {
  display: none;
  left: 100%;
  position: absolute;
  top: 0;
}

.slinky-menu .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slinky-menu .header .title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.4;
  margin: 0;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.slinky-theme-default {
  background: #f6f7f8;
  /*-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);*/
  /*-moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);*/
  /*box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);*/
}

.slinky-theme-default .title {
  color: #333;
  padding: 1em;
}

.slinky-theme-default li {
  line-height: 1;
}

.slinky-theme-default .next:not(.back){
  font-family: "Montserrat-SemiBold",sans-serif;
}
.slinky-theme-default .next:not(.back),
.slinky-theme-default a.next:not(.back){
  color: #333;
  padding: 0;
  font-size: 18px;
}

.slinky-theme-default li > a:not(.back),
.slinky-theme-default .next:not(.back){
  padding: 0;
}

.slinky-theme-default a:not(.back) span,
.slinky-theme-default .next:not(.back) > span{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 23px 18px;
}

.slinky-theme-default .next:not(.back):hover {
  background: rgba(90, 200, 250, 0.25);
}

.slinky-theme-default .next:not(.back):active {
  background: rgba(90, 200, 250, 0.5);
}
.back-icon{
  display: flex;
  padding: 0 1em;
  background: #b4d7ed;
}
.slinky-menu .back-icon + .title{
  /*padding-left: 0;*/
}
.slinky-theme-default .next::after, .slinky-theme-default .back-icon::before {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+ICAgIDxwYXRoIGQ9Ik0xMi4yMTkgMi4yODFMMTAuNzggMy43MiAxOC4wNjIgMTFIMnYyaDE2LjA2M2wtNy4yODIgNy4yODEgMS40MzggMS40MzggOS05IC42ODctLjcxOS0uNjg3LS43MTl6IiAvPjwvc3ZnPg==) center no-repeat;
  background-size: 1em;
  content: '';
  height: 1em;
  opacity: 0.7;
  -webkit-transition: 200ms;
  transition: 200ms;
  width: 1em;
}

.slinky-theme-default .back-icon::before{
  background-image: url("/images/dist/back.svg");
  background-size: 1.8em;
}

.slinky-theme-default .next::after {
  /*margin-left: 1em;*/
  height: auto;
  width: 60px;
  background-color: #b4d7ed;
  /* height: 100%; */
  display: inline-flex;
}

.slinky-theme-default .back-icon::before {
  padding: 1em;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.slinky-theme-default .next:hover::after, .slinky-theme-default .back:hover::before {
  opacity: 0.75;
}

.slinky-theme-default .next:active::after, .slinky-theme-default .back:active::before {
  opacity: 1;
}
