.services-block {
    padding: 60px 0;
    background: #F5F8F4;
    @media #{$sm} {
        padding: 40px 0;
    }
    &__title {
        font-family: $alethia;
        font-size: 4.6rem;
        line-height: 1.3;
        color: $d-black;
        @media #{$md} {
            font-size: 4rem;
        }
        @media #{$sm} {
            font-size: 2.4rem;
        }
    }
    &__list {
        margin-top: 30px;
    }
    &__list-item {
        position: relative;
        margin-top: 20px;
        padding-left: 15px;
        @media #{$md} {
            padding-left: 10px;
        }
        &:first-child {
            margin-top: 0;
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 12px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            transition: all .3s ease-in-out;
            background-color: #4a4a4a;
            pointer-events: none;
            @media #{$md} {
                top: 8px;
                width: 4px;
                height: 4px;
            }
        }
        &:hover::after {
            background-color: $black;

        }
    }
    &__list-link {
        position: relative;
        display: inline-block;
        font-family: $montserrat;
        font-weight: 600;
        font-size: 2.2rem;
        line-height: 1.2;
        color: #4a4a4a;
        text-decoration: underline;
        @media #{$md} {
            font-size: 1.8rem;
        }
        @media #{$sm} {
            font-size: 1.6rem;
        }
        &::after {
            transition: all .3s ease-in-out;
            opacity: 0;
            position: absolute;
            content: "";
            top: 0;
            right: -20px;
            width: 10px;
            height: 100%;
            background: url(../img/svg/arrow-black.svg) center no-repeat;
            @media #{$md} {
                display: none;
            }
        }
        &:hover {
            color: $black;
            &::before, &::after {
                opacity: 1;
            }
        }
    }
}