.page-img {
   max-width: 100%;
}
.page-img.page-iframe {
   padding-top: 0;
   position: relative;
   width: 100%;
   height: 0;
   padding-bottom: 51%;
   margin-top: 2em;
}
.page-iframe iframe{ 
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0; top: -30px;
}
.page {
    padding: 60px 0;
    font-size: $default_pageFontSize;
    color: $d-black;
    overflow: hidden;
    @media #{$lg} {
        padding: 60px 32px;
    }
    @media #{$md} {
        padding: 60px 24px;
    }
    @media #{$esm} {
        padding: 60px 15px;
    }
    & .page-img {
        max-width: 100%;
    }
    h1, h2, h3, h4, h5, h6 {
      max-width: 864px;
      margin-top: 40px;
      font-family: $alethia;
      color: $d-black;
      & + * {
        margin-top: 0!important;
      }
    }

    > *:first-child,
    table td > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    h1 {
        font-size: 4.6rem;
        margin-bottom: 18px;
        line-height: 1.3;
        @media #{$sm} {
            font-size: 3.2rem;
            margin-bottom: 20px;
        }
    }
    h2 {
        font-size: 4rem;
        margin-bottom: 16px;
        line-height: 1.3;
        @media #{$sm} {
            font-size: 2.8rem;
            margin-bottom: 18px;
        }
    }
    h3 {
        font-size: 3.6em;
        line-height: 1.5;
        margin-bottom: 12px;
        @media #{$sm} {
            font-size: 2.6rem;
            line-height: 1.3;
            margin-bottom: 16px;
        }
    }
    h4 {
        font-size: 3.2rem;
        line-height: 1.4;
        margin-bottom: 12px;
        @media #{$sm} {
            font-size: 2.4rem;
            line-height: 1.3;
            margin-bottom: 12px;
        }
    }
    h5 {
        font-size: 3rem;
        line-height: 1.3;
        margin-bottom: 12px;
        @media #{$sm} {
            font-size: 2.2rem;
            line-height: 1.15;
            margin-bottom: 12px;
        }
    }
    h6 {
        font-size: 2.4rem;
        line-height: 1.5;
        margin-bottom: 12px;
        @media #{$sm} {
            font-size: 2.2rem;
            line-height: 1.3;
            margin-bottom: 12px;
        }
        & + ul, & + ol {
            margin-top: 1.2rem;
        }
    }
    p {
        font-family: $montserrat;
        font-weight: 500;
        max-width: 920px;
        margin: 1.8rem 0 0;
        font-size: 1.6rem;
        line-height: 1.8;
        @media #{$sm} {
            font-size: 16px;
            margin: 1.2rem 0 0;
        }
        img, iframe {
        margin: 2.2rem 0;
        display: block;
        }
    }
    
    img, video, iframe {
        max-width: 100%;
        margin: 4em 0;
        @media #{$sm} {
            margin: 0;
        }
    }
    ul {
        margin-top: 1.2rem;
        padding-left: 15px;
        font-weight: 500;
    }
    ol {
        margin-top: 1.2rem;
        padding-left: 30px;
        font-weight: 500;
    }
    li {
        max-width: 920px;
        font-weight: 500;
        list-style: inherit;
        margin-top: 0.75em;
        position: relative;
        font-size: 1.6rem;
        line-height: 1.7;
        & span, & div {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.7;
        }
        @media #{$sm} {
            margin: 1.125em 0;
        }
        &::before {
            content: "";
            position: absolute;
            left: -15px;
            top: 11px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $d-black;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    & ol li::before {
        display: none;
    }
    figure {
        position: relative;
        margin: 4.375em 0 0;
        padding: 5px 0 15px 125px;
        background: url(../img/svg/quotation.svg);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 60px 45px;
        @media #{$sm} {
            margin-top: 60px;
            padding: 88px 0 0;
            background-size: 36px 27px;
        }
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 6px;
            left: 84px;
            top: 0;
            background-color: $yellow;
            @media #{$sm} {
                width: 113px;
                height: 6px;
                top: 59px;
                left: 0;
            }
        }
    }
    blockquote {
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1em;
        line-height: 1.58;
    }
    figcaption {
        font-family: $montserrat;
        font-weight: 500;
        font-style: italic;
        font-size: 0.875em;
        line-height: 1.5;
        margin-top: 0.75em;
    }
    figcaption cite{
        font-family: $montserrat;
        font-weight: 700;
        font-style: italic;
    @media #{$sm} {
        display: block;
        }
    }
    &-table {
        padding-right: 20px;
        @media #{$md} {
            width: calc(100% + 24px);
            overflow: scroll;
        }
    }
    table {
        margin: 4rem 0;
        border-collapse: collapse;
        table-layout: fixed;
        overflow: scroll;
        width: auto;
        thead {
            text-align: left;
            height: 78px;
        }
        th {
            font-size: 1.4rem;
            font-weight: 600;
            color: $white;
            background-color: #949C8D;
            padding: 1.125em;
            width: 261px;
            vertical-align: middle;
            border: 1px solid #d1d1d1;
        }
        td {
            font-size: 1.4rem;
            font-weight: 500;
            padding: 1.125em;
            color: $d-black;
            min-width: 261px;
            border: 1px solid #d1d1d1;
        img {
          margin: 0;
        }
        > *:first-child {
          margin-top: 0;
        }
      }
    }
}