.main-error {
    display: flex;
    flex-direction: column;
}
.error-page {
    font-family: $alethia;
    padding: 110px 0;
    background: url(../img/404.jpg) center no-repeat;
    background-size: cover;
    height: 100%;
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #949C8D;
    &__title {
        display: inline-block;
        font-size: 19rem;
        line-height: 1;
        position: relative;
        text-transform: uppercase;
        & span {
            left: -36px;
            bottom: 18px;
            position: absolute;
            font-family: $alethia;
            font-size: 2rem;
            line-height: 1.3;
            font-weight: 400;
            color: $d-black;
            @media #{$sm} {
                font-size: 1.4rem;
                bottom: 12px;
                left: -28px;
            }
        }
        @media #{$sm} {
            font-size: 12rem;
        }
    }
    &__subtitle {
        margin-top: 15px;
        font-family: $alethia;
        font-size: 2.4rem;
        line-height: 1.3;
        color: $d-black;
        text-transform: uppercase;
        @media #{$sm} {
            font-size: 2rem;
        }
    }
    &__text {
        font-family: $montserrat;
        font-weight: 500;
        margin: 0 auto;
        max-width: 555px;
        margin-top: 14px;
        font-size: 1.6rem;
        line-height: 1.8;
        color: $d-black;
        @media #{$sm} {
            font-size: 1.8rem;
        }
    }
    &__btn {
        margin-top: 28px;
    }
}