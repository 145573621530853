@font-face {
    font-family: 'Alethia Next';
    src: url('../fonts/AlethiaNext-Light.eot');
    src: url('../fonts/AlethiaNext-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AlethiaNext-Light.woff2') format('woff2'),
        url('../fonts/AlethiaNext-Light.woff') format('woff'),
        url('../fonts/AlethiaNext-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('ttf'),
        url('../fonts/Montserrat-Medium.eot') format('eot');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('ttf'),
        url('../fonts/Montserrat-Bold.otf') format('otf'),
        url('../fonts/Montserrat-Bold.eot') format('eot');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
