.descriptor {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../img/index/header.jpg);
    background-position: center;
    padding: 140px 0 51px;
    min-height: 600px;
    @media #{$lg} {
        background-position: 78% center;
    }
    @media #{$md} {
        background-position: top center;
        background-size: auto 405px;
        padding: 435px 0 0;
        & .container {
            display: grid;
            grid-template-columns: 267px 1fr;
            grid-auto-rows: auto;
        }
    }
    @media #{$sm} {
        background-position: 72% 0;
        background-size: auto 293px;
        padding: 313px 0 0;
        min-height: 400px;
        & .container {
            display: block;
            text-align: center;
        }
    }
    &--about {
        background-image: url(../img/about/descriptor.jpg);
        padding: 193px 0 200px;
        @media #{$lg} {
            background-position: 44% center;
        }
        @media #{$md} {
            background-position: 100% top;
            background-size: auto 405px;
            padding: 435px 0 0;
            min-height: 500px;
            & .container {
                display: block;
                text-align: left;
            }
            & .descriptor__text {
                max-width: 594px;
            }
        }
        @media #{$sm} {
            background-position: 80% top;
            background-size: auto 293px;
            padding: 313px 0 0;
            & .container {
                display: block;
            }
        }
    }
    &__title {
        max-width: 452px;
        font-family: $alethia;
        font-size: 4.6rem;
        line-height: 1.3;
        color: $white;
        margin-bottom: 20px;
        @media #{$md} {
            max-width: 100%;
            font-size: 4rem;
            color: $d-black;
            margin-right: 59px;
            margin-bottom: 16px;
            grid-row: 1/3;
        }
        @media #{$sm} {
            font-size: 3.2rem;
            margin-right: 0;
            max-width: 100%;
        }
        &--img {
            font-size: 0;
        }
    }
    &__text {
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.6;
        color: $white;
        max-width: 502px;
        @media #{$md} {
            color: $d-black;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 1.8;
            grid-column: 2;
        }
        @media #{$sm} {
            margin-right: 0;
            max-width: 100%;
        }
        &:not(:first-of-type) {
            margin-top: 7px;
        }
    }
}