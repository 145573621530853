.faq {
    padding: 50px 0 80px;
    &__title {
        font-family: $alethia;
        font-size: 4rem;
        line-height: 1.3;
        color: $d-black;
        @media #{$sm} {
            font-size: 3rem;
        }
    }
    &__subtitle {
        margin-top: 16px;
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.8;
        color: $d-black;
        @media #{$sm} {
            margin-top: 20px;
        }
    }
    &__list {
        margin-top: 60px;
        @media #{$md} {
            margin-top: 35px;
        }
    }
    &__list-item {
        border-bottom: 2px solid rgba(182, 196, 174, 0.3);
        &:last-child {
            border-bottom: 0;
        }
    }
    &__question {
        cursor: pointer;
        position: relative;
        font-family: $alethia;
        font-size: 2.6rem;
        line-height: 1.5;
        padding-top: 20px;
        padding-right: 80px;
        padding-bottom: 20px;
        @media #{$lg} {
            font-size: 2.2rem;
        }
        @media #{$sm} {
            font-size: 2rem;
            padding-top: 24px;
            padding-bottom: 24px;
        }
        &::after {
            position: absolute;
            right: 20px;
            top: 27px;
            content: "";
            width: 18px;
            height: 18px;
            background:
            linear-gradient(#949C8D,#949C8D),
            linear-gradient(#949C8D,#949C8D);
            background-position:center;
            background-size: 100% 2px,2px 100%; /*thickness = 2px, length = 50% (25px)*/
            background-repeat:no-repeat;
            transition: all 0.3s ease-in-out;
        }
        &.open {
            &::after {
                transform: rotate(45deg);
            }
            & + .faq__answer {
                max-height: 1001px;
                opacity: 1;
                z-index: 1;
                bottom: 0;
                padding-bottom: 20px;
            }
        }
    }
    &__answer {
        position: relative;
        bottom: -100px;
        font-family: $montserrat;
        font-size: 1.6rem;
        line-height: 1.8;
        padding-bottom: 0;
        max-height: 0;
        opacity: 0;
        z-index: -1;
        @media #{$sm} {
            font-size: 1.8rem;
        }
    }
}