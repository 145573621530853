.footer {
    background: url(../img/footer-bg.jpg) center no-repeat;
    background-size: cover;
    padding: 40px 0;
    &-inner {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: repeat(2, max-content);
        grid-column-gap: 10%;
        color: $white;
        @media #{$md} {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(3, max-content);
        } 
        @media #{$sm} {
            display: block;
        } 
    }
    &__logo {
        grid-row: 1/2;
        grid-column: 1/2;
        margin-bottom: 38px;
        @media #{$md} {
            grid-row: 1/2;
            grid-column: 1/2;
        } 
        @media #{$sm} {
            margin-bottom: 28px;
            max-width: 121px;
        } 
    }
    &-contacts {
        grid-row: 2/3;
        grid-column: 1/2;
        @media #{$md} {
            grid-row: 2/3;
            grid-column: 1/2;
        } 
    }
    &-links {
        grid-row: 2/3;
        grid-column: 2/3;
        align-self: end;
        @media #{$md} {
            margin-top: 40px;
            grid-column: 2/3;
            grid-row: 1/2;
        } 
        @media #{$sm} {
            display: none;
        } 
    }
    &-social {
        grid-row: 2/3;
        grid-column: 3/4;
        align-self: flex-end;
        @media #{$md} {
            margin-top: auto;
            grid-column: 2/3;
            grid-row: 2/3;
        } 
        @media #{$sm} {
            margin-top: 32px;
        } 
    }
    &__contact {
        margin-bottom: 26px;
        &:last-of-type {
            margin-bottom: 0;
        }
        & a {
            transition: all 0.3s ease-in-out;
            &:hover {
                color: #B6C3AE;
            }
        }
    }
    &__text {
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 170%;
        margin-top: 8px;
    }
    &__link {
        position: relative;
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.3;
        margin-top: 17px;
        transition: all 0.3s ease-in-out;
        &::before {
            position: absolute;                
            top: calc(50% - 4px);
            left: -15px;
            content: "";
            width: 7px;
            height: 7px;
            background-color: #B6C3AE;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.3s ease-in-out;
        }
        &:first-child {
            @media #{$md} {
                margin-top: 0;
            }
        }
        &:hover {
            color: #B6C3AE;
            &::before {
                opacity: 1;
            }
        }
    }
    .social {
        display: flex;
        align-items: center;
        &__link {
            margin-right: 24px;
            width: 45px;
            height: 45px;
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            &--vk {
                background-image: url(../img/svg/vk-footer.svg);
                &:hover {
                    background-image: url(../img/svg/vk-footer-g.svg);
                }
            }
            &--inst {
                background-image: url(../img/svg/inst-footer.svg);
                &:hover {
                    background-image: url(../img/svg/inst-footer-g.svg);
                }
            }
        }
    }
    &__policy {
        display: block;
        margin-top: 35px;
        text-decoration: underline;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3;
        opacity: 0.6;
        @media #{$md} {
            margin-top: 22px;
        }
    }
    &__copyright {
        margin-top: 10px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3;
        opacity: 0.6;
    }
}