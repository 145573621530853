.contacts {
    &__map {
        width: 100%;
        height: 555px;
        position: relative;
    }
    &__label {
        font-weight: 500;
        color: #949494;
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.3;
        margin-bottom: 12px;
        @media #{$sm} {
            font-size: 1.6rem;
        }
    }
    &__info {
        padding: 52px 0 70px;
        background: linear-gradient(to right, transparent 50%, #F5F8F4 50%, #F5F8F4 100%);
        @media #{$md} {
            background: none;
            padding: 60px 0 0;
        }
        & .container--flex {
            align-items: flex-start;
            @media #{$md} {
                display: block;
                padding: 0;
            }
        }
    }
    &__description {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        @media #{$md} {
            flex-wrap: wrap;
            width: 100%;
            padding: 0 24px;
        }
        @media #{$esm} {
            padding: 0 15px;
        }
    }
    &__block {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        @media #{$md} {
            display: grid;
            grid-template-rows: 1;
            grid-template-columns: 1fr 1fr;

        }
        @media #{$sm} {
            margin-top: 10px;
            display: block;
        }
    }
    &__title {
        font-family: $alethia;
        font-size: 3rem;
        line-height: 1.22;
        color: $d-black;
    }
    &__item {
        &:not(:first-child) {
            margin-top: 32px;
        }
        @media #{$md} {
            grid-column: 1;
        }
    }
    &__text {
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.3;
        color: $d-black;
        &:not(:first-of-type) {
            margin-top: 8px;
        }
        @media #{$lg} {
            max-width: 393px;
            font-size: 1.4rem;
            line-height: 1.3;
        }
        @media #{$sm} {
            font-size: 1.8rem;
            line-height: 1.3;
        }
    }
    &__disclamer {
        max-width: 84%;
        margin-top: auto;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 1.5;
        color: #949c8d;
        @media #{$md} {
            grid-row: 1/3;
            grid-column: 2;
            margin-top: 0;
        }
        @media #{$sm} {
            display: none;
            font-size: 2rem;
            margin-top: 20px;
        }
    }
    &__callback {
        padding-left: 72px;
        padding-right: 16px;
        width: 50%;
        @media #{$lg} {
            padding-left: 39px;
        }
        @media #{$md} {
            margin-top: 40px;
            background-color: #F5F8F4;
            width: 100%;
            padding: 50px 24px;
        }
        @media #{$esm} {
            padding: 50px 15px;
            & .form_block--checkbox {
                text-align: center;
            }
        }
    }
    &__subtitle {
        font-weight: 500;
        margin-top: 10px;
        font-size: 1.4rem;
        line-height: 1.5;
        @media #{$md} {
            width: 100%;
        }
        @media #{$sm} {
            font-size: 1.6rem;
        }
    }
}