.articles-list {
    padding: 48px 0 95px;
    overflow: hidden;
    &__subtitle {
        max-width: 920px;
        margin-top: 15px;
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.8;
        color: $d-black;
    }
    &__holder {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        @media #{$md} {
            margin-top: 24px;
        }
        @media #{$sm} {
            margin-top: 30px;
            & .articles__img {
                height: 202px;
            }
        }
    }
    &__item {
        width: calc(33.333% - 16px);
        margin-right: 24px;
        &:nth-child(n + 4) {
            margin-top: 40px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
        @media #{$xxl} {
            width: calc(50% - 11px);
            margin-right: 22px;
            &:nth-child(3n) {
                margin-right: 22px;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(n + 3) {
                margin-top: 40px;
            }
        }
        @media #{$sm} {
            width: 100%;
            margin-right: 0;
            &:nth-child(n + 2) {
                margin-top: 40px;
            }
        }
        & .articles__img {
            height: 274px;
            margin-bottom: 80px;
            @media #{$xxl} {
                height: 283px;
                margin-bottom: 68px;
            }
            @media #{$lg} {
                height: 314px;
                margin-bottom: 42px;
            } 
            @media #{$md} {
                height: 212px;
                margin-bottom: 0;
            }
        }
        & .articles__img img {
            height: 100%;
            object-fit: cover;
            @media #{$xxl} {
                height: auto;
            }
            @media #{$lg} {
                height: 100%;
                width: 100%;
                object-fit: cover;
            } 
            @media #{$md} {
                width: 100%;
            }
        }
    }
}