.fixed-header .header {
    z-index: 900;
}
.header {
    &-row {
        position: relative;
        padding: 11px 0;
        background: $white;
        z-index: 100;
        @media #{$md} {
            &::before {
                content: "";
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 15;
            }
        }
        @media #{$sm} {
            padding: 12px 0;
        }
        &--categories {
            border-top: 2px solid rgba(182, 196, 174, 0.3);
            padding: 0;
            z-index: 10;
            @media #{$md} {
                display: none;
            }
            &::before {
                content: "";
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                z-index: 15;
            }
            @media #{$sm} {
                display: none;
            }
        }
    }
    &__logo {
        margin-right: 51px;
        max-width: 154px;
        @media #{$lg} {
            max-width: 105px;
        }
        @media #{$md} {
            margin-right: 27px; 
            max-width: 83px;
        }
    }
    &__nav {
        flex: 1 0 auto;
        margin-right: 56px;
        @media #{$lg} {
            margin-right: 27px;
        }
        @media #{$md} {
            display: none;
        }
    }
    &__links {
        display: flex;
    }
    &__link {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.5;
        color: $gray;
        transition: all 0.3s ease-in-out;
        &:not(:last-child) {
            margin-right: 32px;
            @media #{$lg} {
                margin-right: 20px;
            }
        }
        &:hover, &.active {
            color: $d-black;
        }
    }
    &__messenger {
        display: flex;
        @media #{$md} {
            margin-left: auto;
        }
        @media #{$esm} {
            display: none;
            margin: 0 auto;
        }
        & li {
            &:not(:last-child) {
                margin-right: 8px;
                @media #{$esm} {
                    margin-right: 35px;
                }
            }
        }
        &-item {
            display: block;
            @media #{$esm} {
                & img {
                    width: 34px;
                }
            }
        }
    }
    &__contact {
        margin-left: 10px;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.5;
        color: $l-black;
        @media #{$lg} {
            margin-left: 15px;
            font-size: 1.8rem;
        }
        @media #{$esm} {
            display: none;
        }
    }
    &__categories {
        display: flex;
        justify-content: space-between;
        flex: 1 0;
    }
    &__category {
        position: relative;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.5;
        flex: 1 0 auto;
        color: $d-black;
        transition: all 0.3s ease-in-out;
        padding: 18px 0;
        @media #{$lg} {
            font-size: 1.6rem;
            padding: 19px 0;
        }
        &:last-child {
            flex: 0 0 auto;
            @media #{$xl} {
                & .header__category-submenu {
                    margin-left: -160px;
                }
            }
            @media #{$lg} {
                & .header__category-submenu {
                    margin-left: -260px;
                }
            }
        }
        &:not(:last-child) {
            &::after {
                position: absolute;
                right: 35px;
                top: calc(50% - 3.5px);
                content: "";
                width: 7px;
                height: 7px;
                background: #b6c3ae;
                border-radius: 50%;
                @media #{$lg} {
                    right: 19px;
                }
            }
        }
        &:hover {
            color: $green;
            & .header__category-submenu {
                max-height: 500px;
                opacity: 1;
                height: auto;
                z-index: 10;
                padding: 40px 36px;
            }
        }
        &.active {
            color: $green;
        }
        &-submenu {
            background: #F5F8F4;
            position: absolute;
            width: 100vw;
            list-style: none;
            margin-left: -60px;
            z-index: 1;
            max-height: 0px;
            overflow: hidden;
            opacity: 1;
            transition: .5s;
            top: 100%;
            & li:not(:first-of-type) {
                margin-top: 15px;
            }
        }
        &-link {
            font-family: $montserrat;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.3;
            color: #4a4a4a;
            &:hover {
                text-decoration: underline;
            }        
        }
    }
    &__callback {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.5;
        color: $black;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        background: #eaede7;
        padding: 21px 30px;
        margin-left: 46px;
        transition: all .3s ease-in-out;
        &:hover {
            background: #d9ddd4;
        }
        @media #{$lg} {
            padding: 21px 20px;
            margin-left: 24px;
        }
    }
    &__burger {
        display: none;
        cursor: pointer;
        width: 46px;
        height: 46px;
        border-radius: 7px;
        padding: 14px 11px;
        margin-left: 20px;
        @media #{$md} {
            display: block;
        }
        @media #{$esm} {
            margin-left: auto;
        }
        span {
            position: relative;
            display: block;
            width: 32px;
            height: 2px;
            background-color: $black;
            transition: transform 0.3s ease-in-out;
            &:not(:last-of-type) {
                margin-bottom: 5px;
            }
        }
        &.active {
            position: relative;
            z-index: 5;
            & span:first-child {
                transform: rotate(45deg);
                top: 7px;
                left: -1px;
            }
            & span:nth-child(2) {
                background-color: transparent;
            }
            & span:last-child {
                transform: rotate(-45deg);
                top: -7px;
                left: -1px;
            }
        }
    }
    .menu__wrapper{
        width: 100%;
        height: calc(100% - 70px);
        position: fixed;
        box-sizing: border-box;
        overflow: hidden;
        top: 70px;
        right: -100%;
        background-color: rgba(0,0,0,.5);
        &.active {
            right: 0;
            & .menu__content {
                right: 0;
            }
        }
    }
    .menu__content {
        position: absolute;
        transition: 0.6s;
        right: -100%;
        height: 100%;
        margin-left: auto;
        max-width: 576px;
        min-width: 370px;
        display: flex;
        flex-direction: column;
        background: $white;
        @media #{$esm} {
            width: 100%;
            min-width: initial;
        }
        & .slinky-theme-default {
            background: $white;
            overflow-y: auto;
        }
        & .slinky-theme-default ul li{
            border-bottom: 1px solid #cdd0cb;
        }
        & .slinky-menu .header span{
            font-family: $alethia;
            font-size: 2.4rem;
            line-height: 1.5;
            color: $d-black;
            padding-left: 0;
        }
        & .slinky-menu.slinky-theme-default a:not(.back) span, .slinky-theme-default .next:not(.back) > span {
            font-family: $alethia;
            font-size: 2.4rem;
            line-height: 1.5;
            color: $d-black;
            padding: 20px 15px;
            @media #{$sm} {
                padding: 15px;
                font-size: 2rem;
            }
        }
        & .slinky-menu.slinky-theme-default .next:not(.back):hover{
            background: $l-gray;
        }
        & .slinky-menu.slinky-theme-default .next:not(.back):hover span{
            color: #000;
        }
        & .slinky-menu.slinky-theme-default .next::after{
            background: url(../img/svg/arrow-r.svg) center no-repeat;
            opacity: 1;
        } 
        & .slinky-menu.slinky-theme-default .back-icon{
            background: url(../img/svg/arrow-l.svg) center no-repeat;
            opacity: 1;
        } 
      }
      .menu__footer {
        padding-top: 10px;
        display: block;
        margin-top: auto;
        text-align: center;
        & .header__messenger {
            display: none;
            @media #{$esm} {
                display: flex;
                justify-content: flex-start;
                padding-left: 15px;
                margin-bottom: 26px;
            }
        }
      }
      .menu__footer-contact {
        display: none;
        font-family: $montserrat;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        color: $d-black;
        @media #{$esm} {
            text-align: left;
            padding-left: 15px;
            display: block;
        }
        }
      .menu__footer-callback {
        margin-top: 20px;
        padding: 24px 10px;
        text-align: center;
        width: 100%;
        font-family: $montserrat;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $d-black;
        position: relative;
        background: #eaede7;
      }
    header .menu__wrapper .js-menu {
        overflow-y: auto;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
          width: 11px;
        }
        &::-webkit-scrollbar:horizontal {
          height: 11px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 2px solid white; /* should match background, can't be transparent */
          background-color: rgba(0, 0, 0, .5);
        }
    }
}