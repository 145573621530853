/*
@todo определить переменные
 */
 /* нужна переменная
 $placeholder_color: #000;
 $placeholder_fontsize: 18px;
 $placeholder_lineheight: 20px;
 */
 .wow {
  visibility: hidden;
}
 html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th {
   margin: 0;
   padding: 0;
   border: 0;
   outline: 0;
   font-weight: normal;
   font-style: normal;
   vertical-align: baseline;
 }
 
 html {
  font-size: 10px;
  font-family: $montserrat;
  color: $black;
 }

 body {
  padding-top: 147px;
  overflow-x: hidden;
  @media #{$md} {
    padding-top: 72px;
  } 
 }
 
 *, *:before, *:after {
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 
 strong,
 b {
   font-weight: bold;
 }
 
 /*
 Стилизация элементов форм по умолчанию
  */
 
 input,
 textarea,
 button {
   // нужна переменная font-family: $font_semibold;
   resize: none;
   outline: none;
 }
 
 table {
   border-collapse: separate;
   border-spacing: 0;
   vertical-align: middle;
 }
 ul {
  list-style-type: none;
 }
 img {
  max-width: 100%;
 }
 h1, h2, h3, h4, h5, h6 {
   font-family: $montserrat;
   // нужна переменная color: $color_title;
   font-weight: normal;
  // нужна переменная font-family: $font_title;
   line-height: 1.3;
 }
 
 h1 {
   font-size: 6rem;
   @media #{$sm} {
       font-size: 3.2rem;
       margin-bottom: 20px;
   }
 }
 
 h2 {
   font-size: 36px;
   @media #{$sm} {
       font-size: 2.8rem;
       margin-bottom: 18px;
   }
 }
 
 h3 {
   font-size: 32px;
   @media #{$sm} {
       font-size: 2.6rem;
       line-height: 1.3;
       margin-bottom: 16px;
   }
 }
 
 h4 {
   font-size: 28px;
   @media #{$sm} {
       font-size: 2.4rem;
       line-height: 1.3;
       margin-bottom: 12px;
   }
 }
 
 h5 {
   font-size: 24px;
   @media #{$sm} {
       font-size: 2.2rem;
       line-height: 1.15;
       margin-bottom: 12px;
   }
 }
 
 h6 {
   font-size: 2rem;
   line-height: 1.3;
   @media #{$sm} {
       font-size: 2rem;
       line-height: 1.3;
       margin-bottom: 12px;
   }
 }
 
 .h48 {
   font-size: 48px;
 }
 
 .h44 {
   font-size: 44px;
 }
 
 .h40 {
   font-size: 40px;
 }
 
 .h36 {
   font-size: 36px;
 }
 
 .h32 {
   font-size: 32px;
 }
 
 .h28 {
   font-size: 28px;
 }
 
 .h24 {
   font-size: 24px;
 }
 
 .h20 {
   font-size: 20px;
 }
 
 .h16 {
   font-size: 16px;
 }
 
 .float-box.form .float-box_inset div.agreements * {
   color: #000;
 }
 
 a {
   cursor: pointer;
   text-decoration: none;
   color: inherit;
   font-weight: inherit;
  // нужна переменная font-family: $font_links;
 }
 
 form {
   display: block;
 }
 
 /* ОБЩИЕ СТИЛИ */
 
 .container {
  width: 1140px;
  margin: 0 auto;
  display: block;
  @media #{$lg} {
   width: 100%;
   padding: 0 32px;
  }
  @media #{$md} {
   padding: 0 24px;
  }
  @media #{$esm} {
   padding: 0 15px;
  }
  &--flex {
   display: flex;
   align-items: center;
  }
  &--xxl {
    width: 1320px;
    margin: 0 auto;
    @media #{$xxl} {
      width: 1140px;
      margin: 0 auto;
    }
    @media #{$lg} {
     width: 100%;
     padding: 0 32px;
    }
    @media #{$md} {
     padding: 0 24px;
    }
  }
}
.section-title {
  font-family: $alethia;
  font-size: 4.6rem;
  line-height: 1.5;
  color: $black;
  &--arrows {
    display: flex;
    justify-content: space-between;
  }
  @media #{$md} {
    font-size: 4rem;
  }
  @media #{$sm} {
    font-size: 3rem;
  }
}
 .body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
 }
 .main {
  flex: 1 0 auto;
  &-product {
    overflow: hidden;
  }
  &-catalog-sub {
    padding-bottom: 60px;
  }
 }
 .link {
  position: relative;
  cursor: pointer;
  &::before {
    position: absolute;
    content: "";
    bottom: -2px;
    width: 0;
    height: 1px;
    background-color: $orange;
    left: 0;
    transition: width 0.3s ease-in-out;
  }
  &:hover {
    &::before {
        width: 100%;
    }
  }
  &--header::before {
    background-color: $yellow;
    bottom: -9px;
    height: 4px;
  }
  &.active::before {        
      width: 100%;
  }
 }
 .slider-arrows {
  position: relative;
  width: 80px;
  @media #{$sm} {
    display: none;
  }
 }
 .swiper-button-next, .swiper-button-prev {
  z-index: 1;
  position: absolute;
  opacity: 1;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-top: 2px solid #000000 !important;
  border-right: 2px solid #000000 !important;
  margin: 0 0;
  display: inline-block;
  transition: 1s;
  opacity: 0.5;
  @media #{$md} {
    top: 4px;
    width: 24px;
    height: 24px;
  }
  &::after {
    display: none;
  }
  &:hover , &:active {
    opacity: 1;
  }
 }
 .swiper-button-prev {
  transform: rotate(-135deg);
  left: 0px;
 }
 .swiper-button-next {
  right: 7px;
  transform: rotate(45deg);
 }
 .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
 }

 /*
 Стили для скрола
 @todo дописать стили под все устройства
  */
 *::-webkit-input-placeholder {
   // нужна переменная color: $placeholder_color;
   // нужна переменная font-family: $font_default;
   // нужна переменная font-size: $placeholder_fontsize;
   // нужна переменная line-height: $placeholder_lineheight;
 }
 
 *:-moz-placeholder {
   // нужна переменная color: $placeholder_color;
   // нужна переменная font-family: $font_default;
   // нужна переменная font-size: $placeholder_fontsize;
   // нужна переменная line-height: $placeholder_lineheight;
 }
 
 *::-moz-placeholder {
   // нужна переменная color: $placeholder_color;
   // нужна переменная font-family: $font_default;
   // нужна переменная font-size: $placeholder_fontsize;
   // нужна переменная line-height: $placeholder_lineheight;
 }
 
 *:-ms-input-placeholder {
  // нужна переменная color: $placeholder_color;
  // нужна переменная font-family: $font_default;
  // нужна переменная font-size: $placeholder_fontsize;
  // нужна переменная line-height: $placeholder_lineheight;
 }
 
 /*
 Кастомный чек-бокс
 @todo провести рефакторинг класса .checkbox и заменить на альтернативное, в случае необходимости
  */

 /*  Дефолтная стилизация прелоуйдера */
 .preloader {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background: rgba(255, 255, 255, 0.8);
   z-index: 5000;
 
   .gooey {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 182px;
     height: 40px;
     margin: -20px 0 0 -71px;
     /*background: white;*/
     /*filter: contrast(20);*/
   }
 
   .dot {
     position: absolute;
     width: 26px;
     height: 26px;
     top: 12px;
     left: 5px;
     /*filter: blur(4px);*/
     background: #214895;
     border-radius: 50%;
     transform: translateX(0);
     animation: dot 2.8s infinite;
   }
 
   .dots {
     transform: translateX(0);
     margin-top: 12px;
     margin-left: 31px;
     animation: dots 2.8s infinite;
 
     span {
       display: block;
       float: left;
       width: 26px;
       height: 26px;
       margin-left: 16px;
       /*filter: blur(4px);*/
       background: #214895;
       border-radius: 50%;
     }
   }
 }
 
 @keyframes dot {
   50% {
     transform: translateX(110px);
   }
 }
 
 @keyframes dots {
   50% {
     transform: translateX(-60px);
   }
 }
 
 /*
 Дефолтная стилизация кнопки "Закрыть" - крестик
  */
 .exit {
   position: absolute;
   right: 40px;
   top: 40px;
   width: 32px;
   height: 32px;
   opacity: 1;
   cursor: pointer;
 
   &:hover {
     opacity: 0.8;
   }
 
   &::before {
     transform: rotate(45deg);
     position: absolute;
     left: 15px;
     content: ' ';
     height: 22px;
     width: 3px;
     background-color: #909090;
   }
 
   &::after {
     transform: rotate(-45deg);
     position: absolute;
     left: 15px;
     content: ' ';
     height: 22px;
     width: 3px;
     background-color: #909090;
   }
 }
 
 /*
 Дефолтная стилизация форм и элементов форм
  */
 form {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none; 
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
  }

   input[type=text],
   textarea {
     padding: 5px 16px;
   }
 
   &.sendler {
     position: relative;
 
     .errortext,
     .response {
       position: absolute;
       left: 0;
       top: 0;
       height: 100%;
       width: 100%;
       display: none;
       text-align: center;
     }
 
     &.sent {
       > * {
         opacity: 0;
       }
 
     }
 
     &.error {
       > * {
         opacity: 0;
       }
     }
   }
 }
 
 /*
   Стилизация ответов после отправки форм
   @todo Положить стили к родителю form - смотри выше и провести рефакторинг кода
  */
 .errortext,
 .response {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   padding: 30px 30px 30px 30px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
 
   .response__title {
     font-size: 30px;
     text-transform: none;
     color: #000;
   }
 }
 
 
 /* Кнопки */
 .btn {
   font-family: $montserrat;
   display: inline-block;
   padding: 14px 47px;
   font-size: 1.4rem;
   line-height: 1.3;
   font-weight: 600;
   color: $white;
   text-align: center;
   border: none;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   background: #949C8D;
   & a {
    text-decoration: none;
   }
   &:hover {
    background: #7a8175;
   }
 }
 .btn-red {
   color: #fff;
   background: #a70000;
 
   &:hover {
     background: #000;
   }
 }
 
 .btn-grey {
  color: $white;
  background-image: linear-gradient(to right, $yellow 50%, #6c6c6c 50%);
  background-size: 201% 100%;
  background-position: right bottom;
 }
 
 
 /* Всплывающие блоки */
 
 @keyframes floatbox {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1
   }
 }
 
 @keyframes floatbox_inset {
   0% {
     right: -110%;
   }
   100% {
     right: 0;
   }
 }
 
 .float-box {
   position: fixed;
   height: 100%;
   width: 100%;
   background: rgba(0, 0, 0, 0.5);
   top: 0;
   left: 0;
   z-index: 910;
   -webkit-animation: floatbox 0.3s ease-in-out;
   -moz-animation: floatbox 0.3s ease-in-out;
   -o-animation: floatbox 0.3s ease-in-out;
   animation: floatbox 0.3s ease-in-out;
 
   .float-box_inset {
     width: 50%;
     min-width: 700px;
     position: absolute;
     right: 0;
     top: 0;
     background: #fff;
     border: 40px #fff solid;
     border-bottom: 20px #fff solid; 
     border-top: 55px #fff solid;
     border-right: 32px #fff solid;
     padding-right: 20px;
     border-left: 35px #fff solid;
     -webkit-animation: floatbox_inset 0.7s ease-in-out;
     -moz-animation: floatbox_inset 0.7s ease-in-out;
     -o-animation: floatbox_inset 0.7s ease-in-out;
     animation: floatbox_inset 0.7s ease-in-out;
     overflow: auto;
     overflow-x: hidden;
     display: block;
     height: 100%;
     -webkit-transition: right 0.5s;
     -moz-transition: right 0.5s;
     -o-transition: right 0.5s;
     transition: right 0.5s;
    @media #{$sm} {
      max-width: 100%;
      min-width: auto;
      width: 100%;
    }
     /*
     @todo скроллбар должен быть определен глобально - см выше
      */
     &, .scrollbar {
       &::-webkit-scrollbar {
         width: 3px;
       }
 
       &::-webkit-scrollbar-button {
         width: 0;
         height: 0;
       }
 
       &::-webkit-scrollbar-track {
         background-color: transparent;
         width: 3px;
         border-right: 2px #fff solid;
         border-left: 2px #fff solid;
         border-radius: 15px;
       }
 
       &::-webkit-scrollbar-track-piece {
         background-color: transparent;
       }
 
       &::-webkit-scrollbar-thumb {
         background-color: #e9e9e9;
         border-radius: 15px;
         width: 3px;
       }
 
       &::-webkit-scrollbar-corner {
         background-color: transparent;
       }
 
       &::-webkit-resizer {
         background-color: transparent;
       }
     }
     & h2 {
      font-family: $alethia;
      font-size: 3.2rem;
      line-height: 1.5;
      margin-bottom: 32px;
      @media #{$sm} {
        font-size: 2.4rem;
      }
     }
     & p {
      font-family: $montserrat;
      font-weight: 500;
      margin: 15px 0;
      font-size: 1.6rem;
      line-height: 1.55;
      @media #{$sm} {
        font-size: 1.8rem;
        line-height: 1.8;
      }
     }
     & ul {
      padding-left: 30px;
      list-style-type: disc;
     }
     & li {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.55;
     }
     > *:first-child {
       margin-top: 0;
     }
 
     > *:last-child {
       margin-bottom: 0;
     }
   }
   &.agreement {
    .float-box_inset {
      @media #{$sm} {
        min-width: initial;
        max-width: 100%;
        width: auto;
        border: 0;
        padding: 46px 45px 30px 15px;
      }
    }
   }
   &.form {
     .float-box_inset {
       min-width: 607px;
       width: 30%;
       display: flex;
       align-items: center;
       text-align: center;
       flex-wrap: wrap;
      @media #{$sm} {
        min-width: initial;
        max-width: 100%;
        width: auto;
        border: 0;
        padding: 20px 30px 0 15px;
      }
       form {
         width: 100%;
         position: relative;
         padding-right: 10px;
         text-align: left;
         @media #{$sm} {
          padding-right: 0;
          }
 
         button {
           //margin-top: 40px;
           margin-bottom: 20px;
           padding: 11px 36px;
           @media #{$sm} {
             padding: 14px 47px;
             margin: 0 auto;
           }
         }
       }
 
       input, label, h3, p {
         display: flex;
         width: 100%;
         margin-bottom: 20px;
       }
       h3 {
        font-family: $alethia;
        font-size: 4.6rem;
        margin-bottom: 16px;
        font-weight: 400;
        @media #{$sm} {
          font-size: 2.6rem;
          line-height: 1.3;
        }
       }
       p {
        font-size: 2rem;
        font-weight: 5 00;
        margin-top: 0;
        margin-bottom: 30px;
        @media #{$sm} {
          font-size: 1.8rem;
          line-height: 1.3;
        }
       }
 
       input {
         border: 1px #939393 solid;
       }
 
       label {
         text-align: left;
         font-size: 1.2rem;
         &::after {
          width: 22px;
          height: 22px;
         }
        @media #{$sm} {
          font-size: 1.2rem;
          flex-wrap: wrap;
          &::after {
           top: -2px; 
           width: 26px;
           height: 26px;
          }
        }
       }
       & form .fw-placeholder .fw-input.wrong+.placeholder+.error-placeholder {
        top: calc(100% - 15px);
       }
     }
 
     .form__logo {
       width: 90px;
     }
   }
 
   .exit {
     right: 22px;
     top: 15px;
     opacity: 0;
     @media #{$sm} {
      right: 20px;
      top: 30px;
    }
   }
 
   &.show .exit {
     opacity: 1;
   }
 }
 
 @media (max-width: 760px) {
   .float-box_inset {
     width: 100% !important;
     min-width: 0;
   }
 }
 
 /*
 Стилизация ответов форм
  */
 
 form.sent {
   > .response,
   > .errortext {
     opacity: 1;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     text-align: left;
     & .h24 {
        font-family: $alethia;
        color: $d-black;
        font-size: 2.4rem;
        line-height: 1.2;
     }
     & p {
      max-width: 429px;
      font-family: $montserrat;
      color: $d-black;
      opacity: 0.7;
      font-weight: 600;
      margin-top: 12px;
      font-size: 2rem;
      line-height: 1.6;
      @media #{$sm} {
        font-size: 1.8rem;
        line-height: 1.8;
        font-weight: 500;
      }
     }
     & .h32 {
      margin-bottom: 15px;
      font-family: $montserrat;
      color: #c90000; 
      font-size: 3.5rem;
      line-height: 1.2;
     }
    }
 }
 
 /*
 эмитация Placeholder
 @todo провести рефакторинг кода
  */
 
   /*
   @todo есть ли другие варианты повесить стили, например по маске класса?
    */
   .load--agreement,
   .load--privacy,
   .load--callback {
     cursor: pointer;
     text-decoration: none;
   }
 
   /*  HEADER  */
   header {
     position: fixed;
     left: 0;
     top: 0;
     display: flex;
     flex-direction: column;
     width: 100%;
     z-index: 5;
     -webkit-transition: top 0.4s, box-shadow 0.2s;
     -moz-transition: top 0.4s, box-shadow 0.2s;
     -o-transition: top 0.4s, box-shadow 0.2s;
     transition: top 0.4s, box-shadow 0.2s;
 
     .mobile-menu {
       cursor: pointer;
       display: none;
       align-items: center;
       flex-wrap: wrap;
       position: relative;
       height: 30px;
       width: 30px;
       z-index: 1200;
       min-width: 30px;
 
       span {
         width: 100%;
         height: 2px;
         background: #000000;
         display: block;
         transition: all 0.5s;
         -webkit-transition: all 0.5s;
         -moz-transition: all 0.5s;
         -o-transition: all 0.5s;
         //&.last {
         //  width: 20px;
         //}
       }
     }
 
     &.show-menu {
       top: 0;
 
       .mobile-menu {
         span {
           position: absolute;
           left: 0;
 
           &.first {
             -moz-transform: rotate(45deg);
             -o-transform: rotate(45deg);
             -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
           }
 
           &.center {
             -moz-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
             -webkit-transform: rotate(-45deg);
             transform: rotate(-45deg);
           }
 
           &.last {
             display: none;
           }
         }
       }
     }
   }
 
   .fixed-header {
     header {
       top: -170px;
 
       &.show, &:hover {
         top: 0;
       }
 
       &.menu__wrapper_active {
         top: 0;
       }
     }
   }
 
   /*
       Дефолтная стилизация страниц управляемых контент-менеджером
   */